export function generateTimeRange(date?: string | number | Date | null) {
  if (date === null || date === undefined) {
    date = Date.now();
  } else if (typeof date === "string") {
    date = new Date(date).getTime();
  } else if (date instanceof Date) {
    date = date.getTime();
  }
  const timeRange: Date[] = [];
  const start = new Date(date);
  start.setHours(0, 0, 0, 0);
  const end = new Date(start);
  end.setHours(23, 59, 59, 999);
  while (start.getTime() < end.getTime()) {
    const next = start.setMinutes(start.getMinutes() + 30);
    timeRange.push(new Date(next));
  }
  return timeRange;
}


